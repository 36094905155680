$(document).render(function () {
    const isSubscribeAvatar = $('#subscribe-avatar').length > 0;
    if (!isSubscribeAvatar) return false;

    var nameChanged = false

    const elms = {
        form: 'form.subscribe-steps',
        stepTitle: '.step-title',
        inputUsername: 'input[name="username"]',
        inputAvatar: 'input[name="avatar"]',
        inputSkill: 'input[name="skill"]',
        submit: 'button[type="submit"]',
        submitHelper: '.step-proceed-info',
        avatarWrapper: '.step-avatars',
        avatarUpload: '#avatar_file',
        avatarUploadButton: '.custom-avatar-file button',
        avatarUploadFilename: '.custom-avatar-file .filename',
        snackbar: '.snackbar',
        gotoWaitingRoom: '#go-to-waiting-room',
    };

    /**
     * To validate all the field
     * @returns {boolean}
     */
    const validateForm = () => {
        const $skill = $(elms.inputSkill.concat(':checked'));
        const $info = $(elms.submitHelper);
        const $submit = $(elms.submit);
        const avatar = $(elms.inputAvatar.concat(':checked')).val();
        let skills = [];

        /**
         * To disable submit button and show error message
         * @param {string} message
         */
        const throwError = (message) => {
            $info.html(message).addClass('is-active');
            $submit.prop('disabled', true);
        };

        // Collect all selected skill
        $skill.each(function () {
            const thisSkill = $(this).val();
            skills.push(thisSkill);
        });

        // if (!avatar) {
        //     // Invalid avatar
        //     return throwError('Select avatar image to proceed');
        // }

        // if (!skills || skills.length < 1) {
        //     // Invalid skill
        //     return throwError('Select one skill to proceed');
        // }

        // All the data valid
        $info.removeClass('is-active');
        $submit.prop('disabled', false);
        return true;
    };

    const showMessage = (message) => {
        $(elms.snackbar).find('.snackbar-body').html(message);
        $(elms.snackbar).addClass('is-active');
    };

    const hideMessage = () => {
        $(elms.snackbar).removeClass('is-active');
    };

    const onAvatarSelect = function (event) {
    	analytic.logEvent('select_avatar', {});
    	analytic.setUserProperties({ avatar: event.target.value })
        $(elms.avatarWrapper).addClass('is-selected');
    };

    const onInputUsername = function() {
    	nameChanged = true;
        const $this = $(elms.inputUsername);
        const length = $this.val().length;
        if(length > 30) {
            $this.val($this.val().substr(0,30))
            return false;
        }
    }

    const onInputSkill = function(event) {
    	analytic.logEvent('select_skill', {});

    	const $skill = $(elms.inputSkill.concat(':checked'));
    	var skills = []
    	$skill.each(function () {
    		skills.push($(this).val());
        });
    	analytic.setUserProperties({ skills: skills })
    }

    const onFileSelect = function () {
        $(elms.avatarUpload).click();
    };

    const onFileChange = function () {
        const filename = $(elms.avatarUpload).val().split('\\').pop();
        $(elms.avatarUploadFilename).text(filename);
        $(elms.avatarWrapper)
            .removeClass('is-selected')
            .find(elms.inputAvatar.concat(':checked'))
            .prop('checked', false);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        var form = $(elms.form)[0];
        var handler = 'onSubscribeAvatar';
        var skills = [];
        var $skill = $(elms.inputSkill.concat(':checked'));

        if (!form) return false;
        if (!validateForm()) return false;
        if ($(elms.submit).hasClass('is-loading')) return false;

        var formdata = new FormData(form);

        hideMessage();
        $(elms.submit).addClass('is-loading');

        $skill.each(function () {
            const thisSkill = $(this).val();
            skills.push(thisSkill);
        });
        formdata.append('skills', skills);

        if(nameChanged) {
        	analytic.logEvent('select_name', {});
        	analytic.setUserProperties({ name: $(elms.inputUsername).val() })
        }

        $.ajax({
            headers: {
                'X-OCTOBER-REQUEST-HANDLER': handler,
            },
            type: 'post',
            cache: false,
            contentType: false,
            processData: false,
            url: '/subscribe-avatar',
            data: formdata,
            success: function (data) {
                if (data.success) {
                    $(elms.gotoWaitingRoom).click();
                } else {
                    showMessage(data.message);
                    if(data['error_type'] !== undefined && (data.error_type == 'room_full' || data.error_type == 'game_finished')) {
                        setTimeout(function(){
                            window.location.href = '/';
                        }, 1500)
                    }
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function () {
                $(elms.submit).removeClass('is-loading');
            },
        });
    };

    // Set username, avatar and skills if user have created avatar before
    const setupDefaultValue = () => {
        if (pageData && pageData.isAvatarCreated) {
            const skills = pageData.userSkills ? pageData.userSkills.split(',') : [];

            if (pageData.username) $(elms.inputUsername).val(pageData.username);
            for (let skill of skills) {
                $(elms.inputSkill.concat(`[value="${skill}"]`)).attr('checked', 'checked');
            }
        }
    };

    let alignStepTitle_lastHeight = 0;

    const alignStepTitle = () => {
        let minHeight = 0;

        $(elms.stepTitle).each(function () {
            const spanHeight = $(this).find('span').height();
            if (spanHeight > minHeight) minHeight = spanHeight;
        });

        if (alignStepTitle_lastHeight !== minHeight) {
            alignStepTitle_lastHeight = minHeight;
            $(elms.stepTitle).css('min-height', minHeight);
        }
    };

    setupDefaultValue();
    validateForm();
    alignStepTitle();
    $(window).on('resize', alignStepTitle);
    $(elms.inputAvatar).on('change', onAvatarSelect);
    $(elms.inputUsername).on('input', onInputUsername);
    $(elms.inputUsername).on('input', validateForm);
    $(elms.inputAvatar).on('change', validateForm);
    $(elms.inputSkill).on('change', onInputSkill);
    $(elms.inputSkill).on('change', validateForm);
    $(elms.form).on('submit', onSubmit);
    $(elms.avatarUploadButton).on('click', onFileSelect);
    $(elms.avatarUpload).on('change', onFileChange);
});
