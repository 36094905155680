/*
	to create a log:
		analytic.logEvent('waiting_room', { });
		analytic.logEvent('create_room', { name: 'room 1'});
*/

const analytic = {
	setup: () => {
		if(!analyticConfig.enableAnalytic) return

		var firebaseConfig = {
        apiKey: analyticConfig.apiKey,
        authDomain: analyticConfig.authDomain,
        databaseURL: analyticConfig.databaseURL,
        projectId: analyticConfig.projectId,
        storageBucket: analyticConfig.storageBucket,
        messagingSenderId: analyticConfig.messagingSenderId,
        appId: analyticConfig.appId,
        measurementId: analyticConfig.measurementId,
    };

    firebase.initializeApp(firebaseConfig);
		firebase.analytics();
		analytic.logEvent = firebase.analytics().logEvent;
		analytic.setUserProperties = firebase.analytics().setUserProperties;

		const urlParams = new URLSearchParams(window.location.search);
		const logEventParam = urlParams.get('login-from-token');
		if(logEventParam) {
			analytic.logEvent('sign_in', {})
		}
	},
	logEvent: (event) => {},
	setUserProperties: (event) => {}
}

analytic.setup()
