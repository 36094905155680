const getRemainingTime = () => {
    try {
        const gameStartedAt = viewData.team.game_started_at_timestamp;
        const timerInMinutes = viewData.config.timerInMinutes * 60;
        const endsAt = gameStartedAt + timerInMinutes;
        const now = +new Date() / 1000;
        const remaining = endsAt - now;
        return Math.ceil(remaining);
    } catch(e) {
        return 0
    }
}

var gameLogic = {
    players: [],
    loaded: false,
    remainingTimeInSeconds: getRemainingTime(),
    delaySingleBrief: 1500,
    changePhase: function() {},
    finished: false
};

// set time after tab is inactive because inactive tab is throttled (setTimeout not working properly)
$(window).focus(function() {
    gameLogic.remainingTimeInSeconds = getRemainingTime()
})

// check if inGame should be match every in-game pages
const inGamePages = ['main-office', 'main-desk', 'archive-document', 'conference-room', 'data-room'];

$(document).render(async function () {
    const getCurrentPage = () => {
        return window.location.pathname.replace('/', '');
    };

    if (!inGamePages.includes(getCurrentPage())) return;
    if (gameLogic.loaded) return;

    const avatarHtml = (player) => {
        return `
            <img
                src="${player.avatar ? player.avatar : helper.defaultAvatar}"
                alt="photo"
                title="${player.name}"
            />
        `;
    };

    const renderViewPlayersInRoom = () => {
        const currentPage = getCurrentPage();

        if (currentPage === 'main-office') {
            const mainOfficePlayers = gameLogic.players
                .filter((player) => player.page === 'main-office')
                .map((player) => avatarHtml(player))
            $('.playerinfo-members').html('').append(mainOfficePlayers);

            const mainDeskPlayers = gameLogic.players
                .filter((player) => player.page === 'main-desk' || player.page === 'archive-document')
                .map((player) => avatarHtml(player))
            $('#playroom-main-desk').html('').append(mainDeskPlayers);

            const dataRoomPlayers = gameLogic.players
                .filter((player) => player.page === 'data-room')
                .map((player) => avatarHtml(player))
            $('#playroom-secretary-room').html('').append(dataRoomPlayers);

            const conferenceRoomPlayers = gameLogic.players
                .filter((player) => player.page === 'conference-room')
                .map((player) => avatarHtml(player))
            $('#playroom-conference-room').html('').append(conferenceRoomPlayers);
        } else if (currentPage === 'main-desk') {
            $('.playerinfo-members')
                .html('')
                .append(
                    gameLogic.players
                        .filter((player) => player.page === 'main-desk' || player.page === 'archive-document')
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(gameLogic.players
                    .filter((player) => player.page === 'main-office')
                    .map((player) => avatarHtml(player)));
        } else if (currentPage === 'archive-laptop') {
            $('.playerinfo-members')
                .html('')
                .append(
                    gameLogic.players
                        .filter((player) => player.page === 'main-desk' || player.page === 'archive-laptop')
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'archive-document') {
            $('.playerinfo-members')
                .html('')
                .append(
                    gameLogic.players
                        .filter((player) => player.page === 'main-desk' || player.page === 'archive-document')
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'conference-room') {
            $('.playerinfo-members')
                .html('')
                .append(
                    gameLogic.players
                        .filter((player) => player.page === 'conference-room')
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(gameLogic.players
                    .filter((player) => player.page === 'main-office')
                    .map((player) => avatarHtml(player)));
        } else if (currentPage === 'data-room') {
            $('.playerinfo-members')
                .html('')
                .append(
                    gameLogic.players
                        .filter((player) => player.page === 'data-room')
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(gameLogic.players
                    .filter((player) => player.page === 'main-office')
                    .map((player) => avatarHtml(player)));
        }
    };

    var lastUpdatePlayersInRoom = (+new Date());
    const setViewPlayersInRoom = (reference) => {
        if (gameLogic.players.length && reference != 'add-member') return renderViewPlayersInRoom(); // TODO check this

        $.post('game-progress/get-players-by-pages', {}, function (res) {
            if (res.status !== 'success') return alert('Error, please reload');

            gameLogic.players = res.players;
            renderViewPlayersInRoom();
            lastUpdatePlayersInRoom = (+new Date());
        });
    };

    realtime.setViewPlayersInRoom = setViewPlayersInRoom

    const initRealtime = (updatePage) => {
        const options = {
            key: viewData.pusherKey,
            room: viewData.user.currentRoom,
            cluster: viewData.pusherCluster,
            user: viewData.user,
        };
        realtime.init(options);
        realtime.listenInGame();
        realtime.listenShareDocument();
        realtime.listenGoToPhase2();
        realtime.listenGoToPhase3();
        realtime.listenGoToPhase4();
        realtime.listenEmailInbox();
        realtime.listenRefreshViewData();
        realtime.listenCallCEO();
        realtime.listenOnFinish();
        realtime.listenGotoDataRoom();
    };

    const updatePage = () => {
        const eventData = { page: getCurrentPage(), userId: viewData.user.id }
        realtime.channel.trigger('client-change-page', { ...eventData, local: false });
        realtime.onChangePage({ ...eventData, local: true })
    }

    $.fn.easySPALoader.Constructor.prototype.updateHistory = function (url, title) {
        // copas from plugins/luketowers/easyspa/assets/js/easyspa.js

        // Check to see if we should update the state or not
        var skipStateChangeKey = url + '-skipStateChange';
        if (window.spaState.getData(skipStateChangeKey)) {
            window.spaState.setData(skipStateChangeKey, false);
        } else {
            window.history.pushState({}, title, url);
        }

        // Update the document title if required
        if (title) {
            document.title = title;
        }

        // Update the current url
        window.spaState.setData('currentUrl', url);
        window.scrollTo(0, 0);

        // modification start from here
        updatePage();
        setTimeout(timer.show, 1);
    };

    const start = () => {
        setViewPlayersInRoom('game-logic.start');
        initRealtime();
        timer.show();

        const pusherConnected = setInterval(() => {
            if(realtime.pusher.connection.state === 'connected') {
                clearInterval(pusherConnected);
                setTimeout(updatePage, 500);
            }
        }, 10)
    };

    gameLogic.showSingleBrief = function(phase) {
        setTimeout(() => {
            $('.singlebrief-title').text($('#brief-title-' + phase.toString()).text())
            $('.singlebrief-content').html($('#brief-body-' + phase.toString()).html())
            $('.toolbox-singlebrief').addClass('is-open')
        }, gameLogic.delaySingleBrief)
    }

    gameLogic.loaded = true;
    start();
});
