$(document).render(function () {
    const isMainOfficePage = $('#main-office').length > 0;
    if (!isMainOfficePage) return false;

    if (pageData.gameProgress.is_first_time) {
        //$('.system-requirement-popup').addClass('is-open');
        $('#intro-video-wrapper').addClass('is-open');
    }

    // $('.system-requirement-close').click(() => {
    //     $('#intro-video-wrapper').addClass('is-open');
    // })

    function setupVideo() {
        const $mainOfficePopup = $('.popup.main-office-popup');
        const $introWrapper = $('#intro-video-wrapper');
        const $videobox = $('#intro-video-wrapper .videobox');
        const $video = $('#intro-video');
        const $playVideo = $('#intro-video-wrapper .videobox-play');
        const $seekbar = $('#intro-video-wrapper .videobox-seekbar span');

        $playVideo.click(function () {
            $video[0].play();
            $videobox.addClass('is-playing');
            // console.log('is-playin');
        });

        $video.on('ended', function () {
            $videobox.addClass('is-loading');
            $videobox.addClass('is-hidden');
            $introWrapper.removeClass('is-open');
            $mainOfficePopup.addClass('is-open');
        });

        $video.on('timeupdate', function () {
            const percentage = ($video[0].currentTime / $video[0].duration) * 100;
            $seekbar.css('width', percentage + '%');
        });

        $video.on('loadstart waiting', function (event) {
            $videobox.addClass('is-loading');
        });

        $video.on('canplay', function (event) {
            $videobox.removeClass('is-loading');
        });

        // $narrativePopup.removeClass('is-open'); // uncomment this line to skip video
        // $mainOfficePopup.addClass('is-open'); // uncomment this line to skip video
    }

    if ($('#intro-video-wrapper').length > 0) setupVideo();

    $('#ready-button').click(function () {
        const $popup = $('.main-office-popup');
        const $timer = $('.navbar-info');

        $popup.removeClass('is-open');
        $timer.addClass('is-active');

        // TODO add single brief
        // $('.toolbox-singlebrief').addClass('is-open')

        // flip is_first_time
        $.post('/game-progress/flip-is-first-time');

        // show briefing 1
        gameLogic.showSingleBrief(1);

        return true;
    });

    $('.main-office-dataroom .popup-close').click(function () {
        $('.main-office-dataroom').removeClass('is-open');
    });

    $('#data-room-btn').click(function () {
        var $btn = $(this).find('a.playroom-room-name');
        if ($btn.hasClass('is-disabled')) {
            return false;
        }
        if (viewData.team.data_room_unlocked) {
            return $('#go-to-data-room').click();
        }
        $('.main-office-dataroom').addClass('is-open');
    });

    // TODO: wrap element above, to this elms variable too
    const elms = {
        password: '.passwordbox',
        passwordInput: '.passwordbox-input',
        passwordKeyValue: '.passwordbox-key[data-value]',
        passwordKeyAction: '.passwordbox-key[data-action]',
        passwordKeyClear: '.passwordbox-key[data-action="clear"]',
        passwordKeySubmit: '.passwordbox-key[data-action="submit"]',
    };

    function passwordboxOnSubmit() {
        const isLoading = $(elms.password).hasClass('is-loading');
        if (isLoading) return false;

        const password = $(elms.passwordInput).val();
        $(elms.password).addClass('is-loading');

        $.request('onCheckDataRoomPassword', {
            data: { password },
            success: function (res) {
                if (res.success && res.is_correct) {
                    $('#go-to-data-room').click();

                    realtime.channel.trigger('client-refresh-view-data', { local: false });
                    realtime.onRefreshViewData({ local: true });

                    // for other members: go to data room when he/she open dataroom password
                    realtime.channel.trigger('client-go-to-dataroom', { local: false });
                } else {
                    $('.main-office-dataroom').addClass('is-error');
                }
            },
            error: function (error) {},
            complete: function () {
                $(elms.password).removeClass('is-loading');
            },
        });
    }

    $(elms.passwordKeyValue).click(function () {
        const isLoading = $(elms.password).hasClass('is-loading');
        if (isLoading) return false;

        const currentVal = $(elms.passwordInput).val().toString();
        const maxLength = parseInt($(elms.passwordInput).attr('maxlength'));
        const value = $(this).data('value').toString();
        const newVal = currentVal + value;
        if (currentVal.length < maxLength) $(elms.passwordInput).val(newVal);
    });

    $(elms.passwordKeyClear).click(function () {
        const isLoading = $(elms.password).hasClass('is-loading');
        if (isLoading) return false;

        $(elms.passwordInput).val('');
    });

    $(elms.passwordKeySubmit).click(passwordboxOnSubmit);
    $(elms.passwordInput).keypress(function (e) {
        const isLoading = $(elms.password).hasClass('is-loading');
        if (isLoading) return false;

        // Enter key
        if (e.keyCode === 13) passwordboxOnSubmit();
    });

    /**
     * ------------------------------------------------------------------------
     * For testing Room coordinate (visual-only)
     * ------------------------------------------------------------------------
     */
    // $('.toggle-transparent').click(function () {
    //     const opacity = parseFloat($playroomWrapper.css('opacity'));
    //     const newOpacity = opacity === 1 ? 0.5 : 1;

    //     $playroomWrapper.css({
    //         opacity: newOpacity,
    //     });
    // });

    // $('.zoom-out').click(function () {
    //     const zoomLevel = $playroomWrapper.data('zoomlevel') || 1;
    //     let newZoomLevel = parseFloat(zoomLevel) - 0.1;

    //     if (newZoomLevel < 0.5) newZoomLevel = 0.5;

    //     $playroomWrapper.data('zoomlevel', newZoomLevel);
    //     $playroomWrapper.css({
    //         transform: `translate(-50%, -50%) scale(${newZoomLevel})`,
    //     });
    // });

    // $('.zoom-in').click(function () {
    //     const zoomLevel = $playroomWrapper.data('zoomlevel') || 1;
    //     let newZoomLevel = parseFloat(zoomLevel) + 0.1;

    //     if (newZoomLevel > 2) newZoomLevel = 2;

    //     $playroomWrapper.data('zoomlevel', newZoomLevel);
    //     $playroomWrapper.css({
    //         transform: `translate(-50%, -50%) scale(${newZoomLevel})`,
    //     });
    // });
});
