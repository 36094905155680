$(document).render(function () {
    window.setupOutroVideo = function () {
        const $videoWrapper = $('#outro-video-wrapper');
        const $videobox = $('#outro-video-wrapper .videobox');
        const $video = $('#outro-video');
        const $playVideo = $('#outro-video-wrapper .videobox-play');
        const $seekbar = $('#outro-video-wrapper .videobox-seekbar span');

        $videoWrapper.addClass('is-open');

        $playVideo.click(function () {
            $videobox.addClass('is-playing');
            $video[0].play();
        });

        $video.on('ended', function () {
            $videoWrapper.addClass('is-loading');
            $('#go-to-end-page').click();
            $videoWrapper.removeClass('is-open');
        });

        $video.on('timeupdate', function () {
            const percentage = ($video[0].currentTime / $video[0].duration) * 100;
            $seekbar.css('width', percentage + '%');
        });

        $video.on('loadstart waiting', function (event) {
            $videoWrapper.addClass('is-loading');
        });

        $video.on('canplay', function (event) {
            $videoWrapper.removeClass('is-loading');
        });
    };
});
