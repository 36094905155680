$(document).render(function () {
    const isHomePage = $('#home-page').length > 0;
    if (!isHomePage) return false;

    if (pageData.isKicked) {
        showMessage(pageData.kickMessage);
        history.pushState({}, '', '/');
    }

    if (pageData.isKickCauseMaxParticipant) {
        showMessage(pageData.kickCauseMaxParticipantMessage);
        history.pushState({}, '', '/');
    }

    $('#join-game').click(function () {
        $('#join-game').addClass('is-loading');

        $('.join-game__error').text($('.join-game__error').data('default-error'));
        $('#popup-join-game').removeClass('is-error');

        const code = $('#input-game-code').val();
        if (!code) {
            $('#join-game').removeClass('is-loading');
            return $('#popup-join-game').addClass('is-error');
        }

        var recaptcha = $('#g-recaptcha-response').val();
        if (recaptcha === '') {
            $('#join-game').removeClass('is-loading');
            $('.join-game__error').text('Are you a robot?');
            return $('#popup-join-game').addClass('is-error');
        }

        const postBody = { code, 'g-recaptcha-response': recaptcha };
        $.request('onEnterCode', {
            data: postBody,
            success: function (res) {
                if (res.success) {
                    if (res.joinSuccess) {
                        $('#go-to-dynamic').attr('href', '/subscribe-avatar');
                        $('#go-to-dynamic').click();
                    } else {
                        $('.join-game__error').text(res.message);
                        $('#popup-join-game').addClass('is-error');
                        grecaptcha.reset();
                    }
                } else {
                    showMessage(res.error_msg);
                }
            },
            error: function (err) {
                showMessage('Error, please try again');
            },
            complete: function (res) {
                $('#join-game').removeClass('is-loading');
            },
        });
    });

    $('#create-game').click(function () {
        $.request('onCreateGame', {
            data: {},
            success: function (res) {
                if (res.success) {
                    $('#go-to-dynamic').attr('href', res.redirect_to);
                    $('#go-to-dynamic').click();
                } else {
                    showMessage('Error, please try again');
                }
            },
            error: function (err) {
                showMessage('Error, please try again');
            },
            complete: function (res) {},
        });
    });

    $('#enter-code').click(function () {
        setTimeout(() => {
            const captchaExist = $('.g-recaptcha iframe').length;
            if (!captchaExist && pageData.enableCaptcha) {
                grecaptcha.render('captcha-wrapper', {
                    sitekey: '6LegqNwZAAAAAPone16KfoF86suExMyoDEMUQuDI',
                });
                //showMessage(pageData.errorLoadingCaptcha);
            }
        }, 1000);
    });
});
