var laptop = {
    inbox: [],
};

$(document).render(function () {
    const elms = {
        laptop: '#popup-laptop',
        laptopOpen: '[data-open="popup"][data-target="#popup-laptop"]',
        laptopClose: '#popup-laptop .popup-close',
        laptopMainscreen: '.laptop-mainscreen',
        laptopBackMainscreen: '[data-target="laptop-mainscreen"]',
        laptopEmail: '.laptop-email',
        laptopEmailOpen: '[data-open="laptop-email"]',
        laptopEmailClose: '[data-dismiss="laptop-email"]',
        laptopHr: '#popup-hr-analytic',
        laptopHrOpen: '[data-open="popup"][data-target="#popup-hr-analytic"]',
        laptopHrClose: '#popup-hr-analytic [data-dismiss="popup"]',
        laptopHrOptSlider: '.laptop-hr-analytic',
        laptopHrOptSliderOpen: '[data-open="laptop-hr-analytic"]',
        laptopDocumentOpen: '[data-open="popup"][data-target="#popup-document-analytic"]',
        laptopDocumentClose: '#popup-document-analytic [data-dismiss="popup"]',

        // Phase 2 Evidence
        laptopEvidence: '.evidence',
        laptopEvidenceOpen: '.evidence-select',
        laptopEvidenceSelectImage: '.evidence-select-image',
        laptopEvidenceSelectTitle: '.evidence-select-title',
        laptopEvidenceItem: '.evidence-item',
        laptopEvidenceItemImage: '.evidence-option-image',
        laptopEvidenceItemTitle: '.evidence-option-title',
        laptopEvidenceOptionWrapper: '.evidence-option-wrapper',
        laptopEvidenceOption: '.evidence-option',
        statement: '.mailbox-statement',
        statementCounter: '.mailbox-statement-counter',
        statementCounterCurrent: '.mailbox-statement-counter .current-length',
        statementInput: '.mailbox-statement-input',

        playroomImageboxShare: '.playroom-imagebox-share',

        // Sometimes there's double escape, this is the main escape of the page
        mainEscape: '#easyspa-container > div > .escape',
        textBoxPhase2: '#email-2-statement',
    };

    $(elms.laptopOpen).click(function () {
        $(elms.mainEscape).addClass('is-hidden');
        realtime.loadInbox(true);
    });

    $(elms.laptopClose).click(function () {
        $(elms.mainEscape).removeClass('is-hidden');
    });

    $(elms.laptopEmailOpen).on('click', function () {
        $(elms.laptopEmail).addClass('is-open');
        $(elms.laptopEmail).on(getTransitionEndEvent(), function () {
            // Prevent prematurely show tutorial when the email hasn't open yet
            if (!$(elms.laptopEmail).hasClass('is-open')) return false;

            // Show Tutorial if has never viewed before
            if (viewedTutorial.indexOf('email') == -1) {
                showEmailTutorial();
                viewedTutorial.push('email');
            }
        });
    });

    $(elms.laptopHrOpen).click(function () {
        $(elms.laptop).addClass('is-viewing-document');
    });

    $(elms.laptopHrClose).click(function () {
        $(elms.laptop).removeClass('is-viewing-document');
    });

    $(elms.laptopDocumentOpen).click(function () {
        $(elms.laptop).addClass('is-viewing-document');
    });

    $(elms.laptopDocumentClose).click(function () {
        $(elms.laptop).removeClass('is-viewing-document');
    });

    $(elms.textBoxPhase2).blur(function () {
        var statement = $(this).val();
        if (!statement || statement.trim() == '') {
            return false;
        }

        $.request('onSaveAnswerPhase2Temp', {
            data: {
                statement: statement,
            },
            success: function (res) {},
            error: function (error) {},
            complete: function () {},
        });
    });

    $(document).on('input', elms.statementInput, function () {
        const length = $(elms.statementInput).val().length;
        const max = parseInt($(elms.statementCounter).data('max'));
        $(elms.statementCounterCurrent).text(length);
        $(elms.statement).toggleClass('is-overload', length > max);
    });

    /**
     * Option: HR Analytic Basic Slider
     */
    // $(elms.laptopHrOptSliderOpen).click(function () {
    //     $(elms.laptopHrOptSlider).addClass('is-open');
    // });

    $('.email-compose').click(function () {
        if (!viewData.user.isLeader) {
            return $('.laptop-email-restricted-popup').addClass('is-open');
        }

        // .whatever-target (with dot)
        const targetClass = '.' + $(this).data('target-class');

        $('.email-mailbox.is-open').removeClass('is-open');
        $(targetClass).addClass('is-open');
        $('.message').removeClass('is-active');
    });

    $(elms.laptopBackMainscreen).on('click', function () {
        $(elms.laptopEmail).removeClass('is-open');
        $(elms.laptopHr).removeClass('is-open');
        $(elms.laptopMainscreen).addClass('is-open');
    });

    /**
     * Email phase 1 onSubmit event
     */
    $('.email-mailbox.template-phase-1 form').submit(function (e) {
        e.preventDefault();

        // const percentage = $('.template-phase-1 input[name="percentage"]').val();
        // const selection = $('.template-phase-1 select[name="selection"]').val();
        const $form = $(this);
        const $fieldset = $form.find('fieldset');
        const $submit = $form.find('button[type="submit"]');

        $fieldset.attr('disabled', true);
        if ($submit.hasClass('is-loading')) {
            return false;
        }
        $submit.addClass('is-loading');
        // console.log('submit: ', percentage, selection);

        var $answers = $('.template-phase-1 .answer-block');
        var data = [];
        $answers.each(function () {
            var $this = $(this);
            var elName = $this.attr('name');
            // console.log(elName);
            var answer = {
                block: elName.substring(elName.lastIndexOf('-') + 1),
                value: $this.val(),
            };
            data.push(answer);
        });

        $.request('onSubmitEmail1', {
            data: {
                recipient: $form.find('#email-1-recipient').val(),
                subject: $form.find('#email-1-subject').val(),
                answers: data,
            },
            success: function (res) {
                if (res.success) {
                    const eventData = { res, phase: 1 };
                    realtime.channel.trigger('client-email-inbox', { ...eventData, local: false });
                    realtime.onEmailInbox({ ...eventData, local: true });

                    if (res.is_correct) {
                        setNewEmailButtonAndPrepareData();
                        realtime.channel.trigger('client-go-to-phase2', {});
                        realtime.onGoToPhase2();
                    }
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function () {
                $submit.removeClass('is-loading');
                $fieldset.attr('disabled', false);
            },
        });
    });

    /**
     * Email phase 2 onSubmit event
     */
    $('.email-mailbox.template-phase-2 form').submit(function (e) {
        e.preventDefault();

        const statement = $('.template-phase-2 textarea[name="statement"]').val();
        if (statement.length < 250) {
            showMessage(viewData.strings.type_min_250_chars);
            return false;
        }
        const $form = $(this);
        const $fieldset = $form.find('fieldset');
        const $submit = $form.find('button[type="submit"]');
        var selectedDocuments = [];

        $('.evidence-item.is-selected').each(function () {
            selectedDocuments.push($(this).data('value'));
        });

        $fieldset.attr('disabled', true);
        $submit.addClass('is-loading');

        $.request('onSubmitEmail2', {
            data: {
                recipient: $form.find('#email-2-recipient').val(),
                subject: $form.find('#email-2-subject').val(),
                statement: $form.find('#email-2-statement').val(),
                sources: selectedDocuments,
            },
            success: function (res) {
                if (res.success) {
                    const eventData = { res, phase: 2 };
                    realtime.channel.trigger('client-email-inbox', { ...eventData, local: false });
                    realtime.onEmailInbox({ ...eventData, local: true });

                    if (res.is_correct) {
                        setNewEmailButtonAndPrepareData();
                        realtime.channel.trigger('client-go-to-phase3', {});
                        realtime.onGoToPhase3();
                        $('#email-3-user-statement').val(res.statement);
                    }
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function () {
                $submit.removeClass('is-loading');
                $fieldset.attr('disabled', false);
            },
        });
    });

    /**
     * Email phase 3 onSubmit event
     */
    $('.email-mailbox.template-phase-3 form').submit(function (e) {
        e.preventDefault();

        const encryption = $('.template-phase-3 input[name="encryption"]').val();
        const statement = $('.template-phase-3 textarea[name="statement"]').val();
        const $form = $(this);
        const $fieldset = $form.find('fieldset');
        const $submit = $form.find('button[type="submit"]');

        $fieldset.attr('disabled', true);
        if ($submit.hasClass('is-loading')) {
            return false;
        }
        $submit.addClass('is-loading');
        // console.log('submit: ', percentage, selection);

        var $answers = $('.template-phase-3 .answer-block');
        var data = [];
        $answers.each(function () {
            var $this = $(this);
            var elName = $this.attr('name');
            // console.log(elName);
            var answer = {
                block: elName.substring(elName.lastIndexOf('-') + 1),
                value: $this.val(),
            };
            data.push(answer);
        });

        $.request('onSubmitEmail3', {
            data: {
                recipient: $form.find('#email-3-recipient').val(),
                subject: $form.find('#email-3-subject').val(),
                answers: data,
            },
            success: function (res) {
                if (res.success) {
                    if (res.is_correct) {
                        realtime.channel.trigger('client-on-finish', { local: false });
                        realtime.onFinish({ local: true });
                    }

                    const eventData = { res, phase: 3 };
                    realtime.channel.trigger('client-email-inbox', { ...eventData, local: false });
                    realtime.onEmailInbox({ ...eventData, local: true });
                }
            },
            error: function (error) {
                try {
                    showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                } catch (e) {
                    showMessage('Oops, an error occured.');
                }
            },
            complete: function () {
                $submit.removeClass('is-loading');
                $fieldset.attr('disabled', false);
            },
        });
    });

    $(document).on('click', '.email-messages .message', function (e) {
        const index = $(this).data('index');
        var body = laptop.inbox[index].email_body;
        const attachments = laptop.inbox[index].attachments || [];
        const title = laptop.inbox[index].email_subject;
        let attachmentMarkup = '<div class="statement-attachment-wrapper">';

        for (let attachment of attachments) {
            const attachmentUrl = `${window.location.origin}/storage/app/media${attachment['attachment']}`;
            const attachmentTitle = attachment['title'];
            attachmentMarkup += `<button
                class="statement-attachment small"
                data-attachment="${attachmentUrl}"
                data-title="${attachmentTitle}">
                    <i class="far fa-paperclip"></i>
                    <span>attachment</span>
            </button>`;
        }

        attachmentMarkup += '</div>';
        body += attachmentMarkup;

        $('#respond-sender').html(laptop.inbox[index].email_sender);
        $('#respond-subject').html(title);
        $('#respond-body').html(body);
        $('#respond-username').html(encodeURI(viewData.user.name));

        $('.email-messages .message').removeClass('is-active');
        $('.email-messages .message').eq(index).addClass('is-active');

        $('.email-mailbox.is-open').removeClass('is-open');
        $('.email-mailbox.template-view').addClass('is-open');

        // read/unread
        const isUnread = $('.email-messages .message').eq(index).hasClass('unread');
        $('.email-messages .message').eq(index).addClass('read');
        $('.email-messages .message').eq(index).removeClass('unread');

        if (isUnread) {
            $.request('onReadInbox', {
                data: {
                    id: laptop.inbox[index].id,
                },
                success: function (res) {},
                error: function (error) {
                    try {
                        showMessage(error.responseJSON.X_OCTOBER_ERROR_MESSAGE);
                    } catch (e) {
                        showMessage('Oops, an error occured.');
                    }
                },
                complete: function () {},
            });
        }
    });

    $(document).on('click', '.statement-attachment', function (e) {
        const $imagebox = $('.playroom-imagebox');
        const $image = $('.playroom-imagebox-image');
        const src = $(this).data('attachment');
        const title = $(this).data('title');
        const newImage = new Image();

        // This is to determine whether tutorial can be shown or not
        // because Tutorial should only be open only if $imagebox is open
        // and $image has been loaded successfully
        // `I` stands for `imagebox`
        let hasIOpened = false;
        let hasILoaded = false;

        const transitionEndEvent = getTransitionEndEvent();

        // Show Tutorial if has never viewed before
        const showTutorial = () => {
            if (!hasIOpened) return false;
            if (!hasILoaded) return false;

            // Show Tutorial if has never viewed before
            if (viewedTutorial.indexOf('attachment') == -1) {
                showEmailAttachementTutorial();
                viewedTutorial.push('attachment');
            }

            // Deregister transition event
            $imagebox.off(transitionEndEvent, onFinishTransition);
        };

        const onFinishTransition = () => {
            // Prevent prematurely show tutorial when the email hasn't open yet
            if (!$imagebox.hasClass('is-open')) return false;

            hasIOpened = true;
            showTutorial();
        };

        $imagebox.on(transitionEndEvent, onFinishTransition);

        newImage.src = src;
        newImage.onload = function () {
            $image.removeClass('is-loading');
            hasILoaded = true;
            showTutorial();
        };

        // $imagebox.addClass('is-fullscreen');

        $image.find('img').remove();
        $image.addClass('is-loading').append(newImage);
        $imagebox.addClass('is-open');
        $(elms.playroomImageboxShare).data('url', src);
        $(elms.playroomImageboxShare).data('title', title);
        share.hideShareButtonIfShared();
    });

    $(elms.laptopEvidenceOpen).click(function (e) {
        const $parent = $(this).parents(elms.laptopEvidenceItem);
        const isOpen = $parent.hasClass('is-open');
        $('.evidence-item button').show();
        $('.evidence-item').removeClass('is-open');

        $('.evidence-item.is-selected').each(function () {
            const url = $(this).data('value');
            $parent.find('button[data-image="' + url + '"]').hide();
        });

        if (isOpen) {
            // Close selectbox
            $parent.removeClass('is-open');
        } else {
            // Open selectbox
            $parent.addClass('is-open');
        }
    });

    $(elms.laptopEvidenceOption).click(function (e) {
        const $parent = $(this).parents(elms.laptopEvidenceItem);
        const $selectTitle = $parent.find(elms.laptopEvidenceSelectTitle);
        const $selectImage = $parent.find(elms.laptopEvidenceSelectImage);
        const title = $(this).data('title');
        const image = $(this).data('image');
        const backgroundImage = $(this).find(elms.laptopEvidenceItemImage).css('background-image');

        // console.log('select one option', { title, image });
        $selectImage.css('background-image', backgroundImage);
        $selectTitle.html(title);
        $parent.data('value', image);
        $parent.addClass('is-selected');
        $parent.removeClass('is-open');

        // enable send button
        var selectedDocuments = [];
        $('.evidence-item.is-selected').each(function () {
            selectedDocuments.push($(this).data('value'));
        });
        if (selectedDocuments.length === $('.evidence-item').length) {
            $('.template-phase-2 button[type="submit"]').removeAttr('disabled');
        }
    });

    /**
     * Close all the opened evidence selectbox when user click outside the element
     */
    $(document).click(function (e) {
        const $openedEvidence = $(`${elms.laptopEvidenceItem}.is-open`);
        const isOutsideEvidence = $(e.target).parents(elms.laptopEvidenceItem).length < 1;
        const isSomeEvidenceOpen = $openedEvidence.length > 0;

        if (isOutsideEvidence && isSomeEvidenceOpen) {
            // console.log('document: ', { isOutsideEvidence, isSomeEvidenceOpen, $target: $(e.target) });
            $openedEvidence.removeClass('is-open');
        }
    });

    const setNewEmailButtonAndPrepareData = () => {
        var phase;
        if (window.phase2Passed) phase = 3;
        else if (window.phase1Passed) phase = 2;
        else phase = 1;

        $('.email-compose .phase').text(` (phase ${phase})`);
        $('.email-compose').data('target-class', `template-phase-${phase}`);
        $('.template-phase-2 button[type="submit"]').attr('disabled', 'disabled');
    };

    const showEmailTutorial = () => {
        if (typeof tutorialObj['email'] == 'undefined') {
            tutorialObj.email = new Tutorial({
                id: '#tutorial-email',
                highlighted: '.email',
                onClose: () => {
                    tutorialObj.email.onViewedTutorial('email');
                },
            });
        }
        tutorialObj.email.open();
    };

    const showEmailAttachementTutorial = () => {
        if(typeof tutorialObj['attachment'] == 'undefined') {
            tutorialObj.attachment = new Tutorial({
                id: '#tutorial-email-attachment',
                highlighted: '.playroom-imagebox-image img',
                onClose: () => {
                    tutorialObj.attachment.onViewedTutorial('attachment');
                },
            });
        }
        tutorialObj.attachment.open();
    }

    setNewEmailButtonAndPrepareData();
});
