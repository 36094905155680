$(document).render(function () {
    const isWaitingRoomPage = $('#waiting-room').length > 0;
    if (!isWaitingRoomPage) return false;

    var isReady;

    analytic.logEvent('waiting_room', {});
    AspirationUI.setup();

    // TODO: Make AspirationUI responsive when window resize
    // $(window).resize(function () {
    //     Aspiration.setup();
    // });

    // load aspiration walls from db
    for (let aspirationWall of pageData.aspirationWalls) {
        var n = document.createTextNode(aspirationWall.name);
        var m = document.createTextNode(aspirationWall.message);
        AspirationUI.append(n, m, aspirationWall.avatar);
    }

    // -------------------------------------------------------------------------------- channel related

    $.get('team/ready-members/' + viewData.user.currentRoom, function (res) {
        $('#start-btn').removeAttr('disabled');
        isReady = res.readyMembers.includes(viewData.user.id.toString());
        const options = {
            key: viewData.pusherKey,
            room: viewData.user.currentRoom,
            cluster: viewData.pusherCluster,
            user: viewData.user,
            readyMembers: res.readyMembers,
        };
        realtime.init(options);
        realtime.listenWaitingRoom();
        realtime.allowStartGame();

        const eventData = {
            isReady,
            userId: viewData.user.id.toString(),
            totalTeamMembers: pageData.totalTeamMembers,
        };
        realtime.onWaitingRoomReadyChange({ ...eventData, local: true });
    });

    $('#copy-btn').on('click', function () {
        const isAnimating = $('.game-info').hasClass('is-success-copy');
        if (isAnimating) return false;

        const teamID = $('.game-info__id').val();
        const isCopied = copyTextToClipboard(teamID);

        if (isCopied) {
            $('.game-info').addClass('is-success-copy');

            setTimeout(() => {
                $('.game-info').removeClass('is-success-copy');
            }, 1000);
        }
    });

    $('#start-btn').click(function () {
        isReady = !isReady;

        const eventData = {
            isReady,
            userId: viewData.user.id.toString(),
            totalTeamMembers: pageData.totalTeamMembers,
        };
        realtime.channel.trigger('client-waiting-room-ready', { ...eventData, local: false });
        realtime.onWaitingRoomReadyChange({ ...eventData, local: true });

        analytic.logEvent('player_ready', {});
    });

    $('.messagebox-form').on('submit', function (e) {
        e.preventDefault();

        const $form = $(this);
        const $fieldset = $form.find('fieldset');
        const $message = $form.find('[name="message"]');
        const $submit = $form.find('[type="submit"]');
        const message = $message.val();
        const name = viewData.user.name;

        $message.val('');

        if (!message) return;

        var n = document.createTextNode(name);
        var m = document.createTextNode(message);
        AspirationUI.append(n, m, viewData.user.avatar);
        realtime.channel.trigger('client-chat', { message, sender: name, avatar: viewData.user.avatar });
        $.post('/aspiration-wall', { message, room_id: viewData.user.currentRoom });
        analytic.logEvent('send_message', {});
    });

    $('#start-game').click(function () {
        $('#confirm-start-all-member-ready').hide();
        $('#confirm-start-not-all-member-ready').hide();

        if (realtime.channel.members.count == realtime.readyMembers.length) {
            $('#confirm-start-all-member-ready').show();
        } else {
            $('#confirm-start-not-all-member-ready').show();
        }

        $('#popup-start-game-opener').click();
    });

    $('#leave-game-popup').click(function () {
        $('#popup-leave-game').addClass('is-open');
    });

    $('#leave-game').click(function () {
        $.request('onLeave', {
            data: {},
            success: function (res) {},
            error: function (err) {
                showMessage('Error, please try again');
            },
            complete: function (res) {
                window.location.href = '/waiting-room';
            },
        });
    });

    $('#force-start').click(function () {
        realtime.channel.trigger('client-start-game', {});
        realtime.onStartGame();
    });

    const onEnterChatBox = function (e) {
        const key = e.which || e.keycode || 0;
        const isEnterKey = key == 13;
        if (isEnterKey && !e.shiftKey) {
            $('.messagebox-form').submit();
            return false;
        }
    };

    const validateChangeTeamName = (teamName) => {
        return teamName.match(/^[#a-z\d\-_\s]+$/i);
    };

    $('#save-team-name-btn').click(function () {
        const teamName = $('#team-name').val();
        if (!validateChangeTeamName(teamName)) {
            return showMessage(pageData.updateTeamNameError);
        }

        $('#save-team-name-btn').addClass('is-loading');
        const senderName = viewData.user.name;
        realtime.channel.trigger('client-change-team-name', { teamName, sender: senderName });

        $.request('onChangeTeamName', {
            data: {
                teamName,
            },
            success: function (res) {},
            error: function (err) {
                showMessage('Error, please try again');
            },
            complete: function (res) {
                realtime.onChangeTeamName({ teamName, sender: senderName, local: true });
                $('#save-team-name-btn').removeClass('is-loading');
            },
        });
    });

    $('textarea[name="message"]').on('keypress', onEnterChatBox);
    $('textarea[name="message"]').on('input', function () {
        const length = $(this).val().length;
        if (length > 150) {
            $(this).val($(this).val().substr(0, 150));
            return false;
        }
    });

    $(document).on('click', '.game-player__delete', function (event) {
        $('#popup-delete-player-opener').click();
        $('#popup-player-name').html(event.target.getAttribute('player-name'));
        $('#confirm-delete').attr({ 'user-id': event.target.getAttribute('user-id') });
    });

    $('#confirm-delete').on('click', function () {
        const userId = $(this).attr('user-id');
        realtime.channel.trigger('client-kick', { userId });
    });

    if (window.location.href.includes('new-leader=1')) {
        history.pushState({}, null, '/waiting-room');
    }
});
